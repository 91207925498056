import React from "react"
import { graphql } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import "@browniebroke/gatsby-image-gallery/dist/style.css"

import Layout from "../../../components/layout"
import Images from "../../../components/images"
import Message from "../../../components/message"
import SEO from "../../../components/seo"
import Section from "../../../components/section"

const LightTreatmentInfo = ({ data }) => {
  const generalImages = data?.generalImages?.nodes?.map(
    ({ childImageSharp }) => childImageSharp
  )

  const beforeAfterImages = data?.beforeAfterImages?.nodes?.map(
    ({ childImageSharp }) => childImageSharp?.fluid
  )

  return (
    <Layout>
      <SEO title="Haarvermindering" />

      <Section>
        <article className="prose lg:prose-xl">
          <h1>Wat gebeurt nu tijdens een haarvermindering</h1>

          <div>
            <Message title="Foto's">
              Ga naar het einde van de pagina voor foto's i.v.m.
              haarvermindering
            </Message>

            <p>
              Alle lichtsystemen (laser, IPL en VPL) werken volgens het principe
              van de “selectieve fotothermolyse”:
            </p>

            <ul>
              <li>
                <b>Fotothermolyse:</b> licht wordt aangetrokken door de
                kleurstof (melanine, pigment) van het haartje en wordt
                vervolgens omgezet in warmte. Deze warmte, die minimum 65°
                Celsius moet bereiken, vernietigt de groeicellen binnenin het
                haarzakje.
              </li>
              <li>
                <b>Selectief:</b> de hierboven beschreven omzetting van licht in
                warmte dient enkel te gebeuren binnen de haarfollikel
                (haarzakje), zonder het omliggende weefsel (de huid) mee op te
                warmen.
              </li>
              <li>
                <b>Werkende processen voor effectieve definitieve ontharing:</b>
                <ul>
                  <li>Het haar bevat melanine (pigment)</li>
                  <li>Het licht wordt geabsorbeerd door de melanine</li>
                  <li>
                    Het geabsorbeerde licht wordt omgezet in warmte-energie dat
                    het haarzakje vernietigt.
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <p>
            Door de groeifases heen wordt de behandeling herhaald tot de
            reductie van haar het gewenste resultaat heeft behaald naar de
            wensen van de klant. De hoeveelheid van behandelingen hangt dus af
            van het beoogd resultaat.
          </p>

          <h2>Foto's</h2>
          <h3>Algemene foto's</h3>
          <p>
            Deze foto's geven een algemeen beeld van hoe haarvermindering werkt
          </p>

          <Message>
            Klik op de afbeelding om ze te bekijken op volledige grootte
          </Message>

          <div className="mx-4">
            <Gallery images={generalImages} />
          </div>

          <h3>Voor/na foto's</h3>
          <p>
            Krijg een indruk van wat de resultaten zijn van haarvermindering
          </p>
          <Images size="medium" data={beforeAfterImages} />
        </article>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  fragment GalleryImages on FileConnection {
    nodes {
      childImageSharp {
        thumb: fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
        full: fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }

  fragment RegularImages on FileConnection {
    nodes {
      childImageSharp {
        fluid(maxWidth: 800) {
          originalName
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }

  query LightTreatmentInfoPageQuery {
    generalImages: allFile(
      filter: { relativeDirectory: { eq: "info/vpl/general" } }
      sort: { fields: name, order: ASC }
    ) {
      ...GalleryImages
    }
    beforeAfterImages: allFile(
      filter: { relativeDirectory: { eq: "info/vpl/before-after" } }
      sort: { fields: name, order: ASC }
    ) {
      ...RegularImages
    }
  }
`

export default LightTreatmentInfo
